/* Provide sufficient contrast against white background */
@import url("https://use.typekit.net/vwt7nvx.css");

html {
    --main-client-color: #e9e0f2; /*to use: var(--main-client-color); */
    --main-theme-color: white; /*to use: var(--main-theme-color); */
    --ces-brand-color: #018089; /* updated from accessibility #3b8896; */ /*to use: var(--ces-brand-color);  use for pages before login*/
}


body {
    font-family: museo-sans, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1rem;
    /*overflow: hidden;*/
}

b, strong {
    font-weight: 700;
}

h1, h2, h3, h4, h5 {
    color: #555555;
}

h1 {
    font-size: 2rem;
    letter-spacing: -1px;
    line-height: 1;
}

h2 {
    font-size: 1.5rem;
}

h1.loginHeading {
    font-size: 1.5rem;
    text-align: center;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    /*border-radius: 25px;*/
    color: var(--main-theme-color);
    background-color: var(--main-client-color);
    border: 2px solid var(--main-client-color);
    border-color: var(--main-client-color);
    /*font-size: 1.2rem;*/
}

.btn.btn-primary:hover, .btn.btn-primary:focus {
    color: var(--main-client-color);
    border: 2px solid var(--main-client-color);
    background-color: var(--main-theme-color);
}

.btn-primary.cesbrand {
    color: var(--main-theme-color);
    background-color: var(--ces-brand-color);
    border-color: var(--ces-brand-color);
}

    .btn-primary.cesbrand:hover, .btn-primary.cesbrand:focus {
        color: var(--ces-brand-color);
        background-color: var(--ces-theme-color);
        border-color: var(--ces-brand-color);
    }

.border.border-primary {
    border-color: var(--main-client-color) !important;
}

/*----Custom scrollbar, doesn't work in Firefox----*/
/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }



.loginLogo {
    width: 100%;
    text-align: center;
}

    .loginLogo img {
        width: 50%;
        height: auto;
    }

.loginbox {
    /*margin-top: 70px;*/
    background: white;
    padding: 15px;
    padding-top: 30px;
    border-radius: 10px;
    /*border: 1px solid #eee;*/
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
}


.Logo {
    max-width: 250px;
    max-height: 50px;
}

@media (max-width: 414px) {
    .Logo {
        max-width: 65px;
    }
}

.leftColumn, .openChatParent {
    overflow-y: auto;
    height: 100%;
}


/*----OpenChat----*/
@media (max-width: 768px) { /*---This should really be 767 but if set to that it doesn't cut in until 766---*/
    .openChatParentContainer {
        position: fixed;
        max-height: 60vh;
        bottom: 0;
        right: 0;
        background-color: white;
        z-index: 5;
        width: 100%;
        border: 1px solid lightgrey;
        border-radius: 5px;
    }
}


@media (min-width: 768px) and (max-width: 1023px) {
    .openChatParentContainer {
        animation: slideOutChat 0.5s linear 1;
    }

    @keyframes slideOutChat {
        0% {
            width: 0%;
        }

        100% {
            width: 100%;
        }
    }
}

@media (min-width: 1024px) {
    .openChatParentContainer {
        animation: slideOutChat 0.5s linear 1;
    }

    @keyframes slideOutChat {
        0% {
            width: 31px;
        }

        100% {
            width: 33%;
        }
    }
}



.noVidColumn {
    width: 100%;
    max-width: 40vw;
}

@media (max-width: 500px) {
    .noVidColumn {
        width: 100%;
        max-width: 100%;
    }
}

.min-vh-75 {
    min-height: 75vh;
}

.openChatParent {
    /*border: white;*/
    background-color: rgba(250, 250, 250, 0.8);
    border-radius: 7px;
    position: relative;
    font-size: 0.85rem;
    padding: 0.2rem;
}

    .openChatParent .resize {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }

.openChatCommentButton {
    color: #0366D6;
    font-weight: normal;
    font-size: 0.8rem;
}

.postContent {
    /*border-radius: 3px;*/
    border-radius: 0px 5px 5px 5px;
    border: 1px solid #a0d6fa;
    background-color: #cfeafc;
    /*background-color: var(--main-client-color);*/
    /*color: var(--main-theme-color);*/
    /*    border: 1px solid #e0e0e0;
    background-color: #f4f4f4;*/
    animation: newpost-fade 1s linear 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.postOwner .postContent {
    border: 1px solid lightgray;
    /*border: 1px solid var(--main-client-color);*/
    background-color: white;
    color: #101010;
    font-weight: 400;
    /*border-radius: 3px;
    background-color: #ECFFE8;*/
    /*    border: 1px solid #e0e0e0;
    background-color: #f4f4f4;*/
}

.openChatReply.postContent {
    /*    border: 1px solid #a0d6fa;
    background-color: #cfeafc;*/
    border: 1px solid #e0e0e0;
    background-color: #f4f4f4;
    opacity: 1;
    color: dimgrey;
}

.postHidden .postContent {
    background-color: #F0F0F0;
    color: dimgrey;
    border-color: grey;
}

@keyframes newpost-fade {
    0% {
        border-color: darkblue;
        background: lightblue;
        color: white;
    }

    100% {
        background: none;
        color: none;
    }
}


.postContent .dropdown-menu {
    min-width: 6rem;
}

.postContent .readMoreButton {
    font-size: 0.9em;
}

.openChatLikeDislike .btn-sm {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.likeCountDisplay {
    font-size: 0.8em;
    min-width: 16px;
    text-align: center;
}

.openChatParent .dropdown-toggle::before {
    display: none;
}

.openChatModal .form-check-input:checked {
    background-color: var(--main-client-color);
    border-color: var(--main-client-color);
}

.openChatModal legend {
    font-size: 1rem;
}

.openChatContentHolder {
    position: relative;
}

.scrollToBottomButton.btn {
    border-radius: 50%;
    position: absolute;
    bottom: 110px;
    right: 10px;
    color: var(--main-theme-color);
    background-color: var(--main-client-color);
    width: 32px;
    height: 32px;
    text-align: center;
}

.scrollToBottomButton .svg-inline--fa {
    width: 14px;
    height: 14px;
}

.emojiPickerContainer {
    position: absolute;
    bottom: 100px;
    left: 0;
    background-color: white;
    z-index: 2;
    -webkit-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.75);
}

.btn-chatTabParent {
    padding: 0;
    height: 0;
    width: 35px;
    position: fixed;
    top: 20%;
    right: 0;
}

.btn-chatTab {
    display: block;
    transform-origin: top right;
    /* Note: for a CLOCKWISE rotation, use the commented-out
     transform instead of this one. */
    /*transform: rotate(-90deg) translate(-100%);*/
    transform: rotate(90deg);
    /* Not vital, but possibly a good idea if the element you're rotating contains
     text and you want a single long vertical line of text and the pre-rotation
     width of your element is small enough that the text wraps: */
    white-space: nowrap;
    border: 2px solid var(--main-client-color);
    color: var(--main-client-color);
    background-color: white;
    z-index: 5;
}


.btn-chatTab {
    transform: rotate(90deg) translate(0, -100%);
    transform-origin: top left;
}



@media only screen and (max-width:767px) {
    .btn-chatTabParent {
        top: 30%;
    }
}

.openChatTabShow {
}


/*----End OpenChat----*/
.cloud {
    background-color: #Fff;
    border: 1px solid #efefef;
    border-radius: 1px;
    padding: 15px;
    opacity: 0.95;
    vertical-align: central;
    /*height: calc(100vh - 150px);
    overflow-y: scroll;*/
}

.cloudVoting {
    background-color: #f9f9f9;
    border: 1px solid #efefef;
    border-radius: 1px;
    padding: 7px;
    opacity: 0.95;
    vertical-align: central;
    margin-bottom: 10px;
}

@media only screen and (max-width: 896px) {
    .cloudVoting {
        padding: 7px;
    }
}

.cloudWelcome {
    background-color: #F9F9F9;
    border: 1px solid #efefef;
    border-radius: 1px;
    padding: 10px;
    opacity: 0.95;
    vertical-align: central;
    max-height: 100%;
}

    .cloudWelcome h2 {
        margin-bottom: 20px;
    }

    .cloudWelcome .table table td {
        border: 1px solid grey;
        padding: 0.5em;
    }

    .cloudWelcome .table table th {
        text-align: center;
    }

.barChart {
    width: 100%;
    max-width: 1000px;
}

.cloudResult {
    background-color: #F9F9F9;
    border: 1px solid #e0e0e0;
    border-radius: 1px;
    padding: 15px 30px;
}

.ResultsContainer {
    padding: 15px;
}

.cloudres {
    background-color: #f9f9f9;
    border: 1px solid #efefef;
    border-radius: 1px;
    padding: 15px;
    opacity: 0.95;
    vertical-align: central;
    margin-bottom: 10px;
    box-shadow: 16px 16px 8px -13px rgba(0,0,0,0.19);
}

.resolutionAccordion .contestTitle {
    border-bottom: 1px solid #eee;
    border-radius: 3px;
    padding: 5px;
    background-color: #fff;
    margin: -5px;
    margin-bottom: 5px;
}

.card-body .resolutionAccordion .contestTitle {
    border-bottom: 0px solid #eee;
    /* border-radius: 3px;*/
    padding: 5px;
    background-color: #fff;
    margin: -5px;
    margin-bottom: 5px;
}

.contestTitle .card-body {
    background-color: #fcfcfc;
    border-top: 1px solid #eee;
    margin: 0 -5px -5px -5px;
}

.contestTitle .card-header {
    padding: 0.25rem;
}

.contestTitle h3 {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0px;
}


.cloudGrid table {
    margin-bottom: 0;
}

.cloudGrid td {
    padding: 5px;
}

    .cloudGrid td:nth-child(n+2) {
        text-align: center;
    }

.cloudGrid th {
    padding: 5px;
}

    .cloudGrid th:nth-child(n+2) {
        text-align: center;
    }

.cloudres p {
    margin-bottom: 5px;
}

.proxyCloud {
    margin-top: 15px;
}



.footer {
    margin-top: 1px;
    /*border-top: 1px solid #e0e0e0;*/
    background-color: var(--main-client-color);
    color: var(--main-theme-color);
    /*background-color: #EBF3F4;*/
    /*    position: fixed;
    bottom: 0;
    z-index: 0;*/
    /*height: 55px;*/
}

    .footer a {
        color: var(--main-theme-color);
        text-decoration: none;
    }

.footerLogo {
    display: inline-block;
    max-width: 100px;
    padding: 9px;
}

    .footerLogo img {
        width: 100%;
        height: auto;
    }

.appParentLogin .footer {
    margin-top: 15px;
    /*border-top: 1px solid #e0e0e0;*/
    background-color: var(--ces-brand-color);
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 0;
    /*    display: flex;
    align-items: flex-end;
    justify-content: flex-end;*/
}

@media only screen and (max-width: 896px) {
    .footer {
        width: 100%;
    }
}

.footer .row {
    margin-left: 15px;
    margin-right: 15px;
}




.titlebar {
    margin-left: 15px;
    margin-right: 15px;
}

@media only screen and (max-width: 896px) {

    .titlebar h1 {
        margin-top: 20px;
    }
}

.fillheight {
    margin-left: 7px;
    margin-right: 7px;
    overflow: hidden;
}


@media only screen and (max-width: 400px) {
    .fillheight {
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (min-width: 768px) {
    .fillheight {
        height: 100%;
    }

    .scrollFix {
        height: 100%;
        overflow: hidden;
    }

    .scrollContainer {
        height: 100%;
        overflow: auto;
    }
}

@media only screen and (max-width: 767px) {
    .fillheight {
    }
}


.main-secondary {
    flex: 1;
    position: relative;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}

.mainContent {
    flex: 1;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% 100%
}

main {
    height: 100%;
}

.headerContent {
}

.footerContent {
}

.appParent {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: stretch;
}

@media (min-width: 768px) {
    html, body {
        height: 100vh;
        overflow: hidden;
        overflow: clip;
    }

    .appParent {
        position: relative;
        height: 100vh;
        overflow: hidden;
        overflow: clip;
    }
}





.headpad {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.policyPadding {
    margin-top: 20px;
}

@media only screen and (max-width: 896px) {
    .headpad {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}

.headpadLogin {
    margin-bottom: 30px;
}

@media (min-width: 896px) {
    .appParent {
        flex-direction: column;
    }

    .headpad {
        height: 100px;
        border-bottom: solid 2px var(--main-client-color);
        padding-top: 10px;
        text-align: right;
        position: sticky;
        top: 0;
        z-index: 5;
        background: #ffffff;
    }

    .headpadLogin {
        height: 100px;
        border-bottom: solid 0px var(--main-client-color);
        padding-top: 10px;
        text-align: right;
        position: sticky;
        top: 0;
        z-index: 5;
    }
}

.validationMessage, .validationalert {
    color: red;
}

/*-----------------------NavBar  --------------------*/
.topbar {
    /*background-color: var(--main-client-color);*/
    /*color: var(--main-theme-color);*/
    z-index: 6;
    border-bottom: 1px solid #f4f4f4;
}

.nav-item:not(.logout) {
    margin-right: 2em;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    /*color: var(--main-theme-color);*/
    border-radius: 1px;
    align-items: center;
    color: black;
    /*    border-right: 1px solid;
    border-left: 1px solid;
    border-color: rgba(255,255,255,0.1);*/
}


.navbar-toggler {
    /*background-color: rgba(255, 255, 255, 0.1);*/
}


@media (min-width: 896px) {

    .navbar-toggler {
        display: none;
    }
}

@media (min-width: 896px) {
    .nav-link .icon {
        font-size: 1rem;
    }

    .nav-link.highlightlink {
        /*        border: 1px solid rgba(237,28,36,0.3);
        background: rgba(237,28,36,0.9);*/
        text-decoration: underline;
    }
}

.white_text {
    color: white !important;
    text-align: center;
}

.nav-link.active, .nav-link.isactive {
    /*    background: rgb(0,0,0);
    background: -moz-linear-gradient(75deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 100%, rgba(2,0,36,1) 100%);
    background: -webkit-linear-gradient(75deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 100%, rgba(2,0,36,1) 100%);
    background: linear-gradient(75deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 100%, rgba(2,0,36,1) 100%);
    color: var(--main-theme-color) !important;*/
    border-bottom: 1px solid grey;
}



/*-------------End NavBar -------------------------------*/



/*-------------Ask a question -------------------------------*/

.askaquestionbox {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
}

    .askaquestionbox .askaquestionbuffer {
        flex: 1 0 auto;
    }

    .askaquestionbox .askaquestion {
        background-color: rgba(255,255,255,1);
        -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.37);
        -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.37);
        box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.37);
        border-radius: 30px;
        flex-shrink: 0;
        text-align: right;
        position: fixed;
        padding: 5px;
        margin-right: 20px;
        margin-bottom: 65px;
        right: 0;
        bottom: 0;
    }

        .askaquestionbox .askaquestion button {
            border-radius: 50%;
            height: 3rem;
            width: 3rem;
            background-color: var(--main-client-color);
            color: var(--main-theme-color);
        }

#chatblock {
    position: fixed;
    bottom: 80px;
    right: 50px;
    z-index: 7;
    background: var(--main-client-color);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.37);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.37);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.37);
    width: 300px;
    border-radius: 5px;
}

@media only screen and (max-width: 767px) {
    #chatblock {
        bottom: 0;
        right: 0;
        width: 100vw;
        height: auto;
    }
}


#chat-dropdown-options::after {
    display: none;
}

.chatTitle {
    color: var(--main-theme-color);
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 0px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    font-size: 0.8rem;
    position: relative;
}

.closeWindow {
    font-weight: 700;
    line-height: 1;
    color: var(--main-theme-color);
    text-shadow: 0 1px 0 #fff;
    position: absolute;
    right: 10px;
    top: 16px;
    font-size: 1rem;
    background: transparent;
    border: 0;
    padding: 0;
}

.messageListwrapper {
    height: 300px;
    background: #ffffff;
}

#messagesList {
    display: block;
    /*height: 300px;*/
    /*        overflow-y: scroll;*/
    background-color: white;
    padding-left: 10px;
    padding-top: 5px;
}

@media only screen and (max-width: 767px) {
    #messagesList {
        display: block;
        /*height: 60vh;*/
        /*            overflow-y: scroll;*/
        background-color: white;
        padding-left: 10px;
        padding-top: 5px;
    }
}

.messageRecMsg {
    margin-left: 0px;
    margin-bottom: 5px;
    margin-right: 10px;
    overflow-wrap: anywhere;
}

.askaquestion button {
    position: relative;
}

.messageAlertCounter {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fc5c65;
    color: white;
    border-radius: 50%;
    font-size: 0.7em;
    height: 17px;
    width: 17px;
    text-align: center;
}

.type_msg {
    background-color: rgba(255,255,255,.9);
    border: 2px;
    border-color: rgb(0,0,0);
    border-radius: 0px;
    height: 60px;
    overflow-y: auto;
    font-size: 0.8rem;
}



@media only screen and (max-width: 812px) {
    .type_msg {
        font-size: 1rem;
    }
}


#chatblock .partialWidthFloatLabel {
    width: calc(100% - 39px);
}


.send_btn {
    /*border-radius: 0 15px 15px 0 !important;*/
    background-color: rgba(0,0,0,0.3);
    border: 0;
    color: var(--main-theme-color);
    cursor: pointer;
    height: 100%;
    width: 40px;
}

.submit_request {
    background-color: var(--main-client-color);
    color: white;
}

#messagesList::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
}

#messagesList::-webkit-scrollbar-track {
    background: rgba(255,255,255,0); /* color of the tracking area */
}

#messagesList::-webkit-scrollbar-thumb {
    background-color: #093e52; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
    /*border: 2px solid rgba(255,255,255,0.3); */
}

.type_msg::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
}

.type_msg::-webkit-scrollbar-track {
    background: rgba(255,255,255,0); /* color of the tracking area */
}

.type_msg::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,0.8); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    /*border: 2px solid rgba(255,255,255,0.3); */
}

.chatBubbleLeft {
    background: #EBF3F4;
    padding: 5px 10px 2px 10px;
    margin-bottom: 10px;
    position: relative;
    font-size: 0.8rem;
    width: 79%;
    margin-left: 20%;
    margin-right: 0;
    border-radius: 4px;
}

.chatBubbleLeftSpeak {
    background: #fbcbcb;
    padding: 5px 10px 2px 10px;
    margin-bottom: 10px;
    position: relative;
    font-size: 0.8rem;
    width: 79%;
    margin-right: 0;
    margin-left: 20%;
    border-radius: 4px;
}

    .chatBubbleLeftSpeak .chatUsername, .chatBubbleLeftSpeak .userIcon {
    }

.chatBubbleLeftPoint {
    background: #cbfbd3;
    padding: 5px 10px 2px 10px;
    margin-bottom: 10px;
    position: relative;
    font-size: 0.8rem;
    width: 79%;
    margin-right: 0;
    margin-left: 20%;
    border-radius: 4px;
}

.chatBubbleLeftTech {
    background: #e5cefc;
    padding: 5px 10px 2px 10px;
    margin-bottom: 10px;
    position: relative;
    font-size: 0.8rem;
    width: 79%;
    margin-left: 20%;
    margin-right: 0;
    border-radius: 4px;
}

.chatBubbleRight {
    background: #DFEFF1;
    padding: 5px 10px 2px 10px;
    border-left: 10px;
    border-right: 5px;
    margin-bottom: 10px;
    position: relative;
    font-size: 0.8rem;
    width: 80%;
    border-radius: 4px;
}

.chatDate {
    font-size: 0.6rem;
    color: #444444;
    float: right;
}

.chatUsername {
    font-size: 0.8rem;
    color: #222222;
}

.messageRecUser {
    font-weight: bold;
    color: #7f8c8d;
}

.userIcon {
    color: #222222;
    padding-right: 10px;
}

.adminIcon {
    color: #093e52;
    padding-right: 10px;
}

/*-------------End ask a question -------------------------------*/
.submitbutton {
    margin-top: 15px;
    margin-bottom: 15px;
}

    .submitbutton input {
        color: #ffffff;
        border: 2px solid var(--main-client-color);
        background-color: var(--main-client-color);
        width: 100%;
    }

    .submitbutton button {
        border: 2px solid var(--main-client-color);
        background-color: var(--main-client-color);
        color: var(--main-theme-color);
        width: 100%;
    }

        .submitbutton button:hover, .submitbutton button:focus, .submitbutton input:hover, .submitbutton input:focus {
            color: var(--main-client-color);
            border: 2px solid var(--main-client-color);
            background-color: var(--main-theme-color);
        }

/*----------- Resolution Display -----------------*/
.VoteRadio .form-check {
    text-align: center;
}

.VoteRadio {
    margin-bottom: 0;
}


.labeloption {
    position: relative;
}

    .labeloption:focus-within {
        box-shadow: 0 0 0 0.1rem rgba(0,0,0,0.8);
    }


.voteOptionsRow {
    padding-top: 15px;
}

    .voteOptionsRow input[type="radio"] {
        z-index: -999;
        position: absolute;
    }

    .voteOptionsRow input[type="checkbox"] {
        z-index: -999;
        position: absolute;
    }

.optcolhighlight {
    /*border-right: 2px solid #093D52;
    border-top: 2px solid #093D52;
    border-bottom: 2px solid #093D52;*/
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
    color: white;
}

div.form-check.col.optcolhighlight {
    /*border-right: 2px solid #093D52;
    border-top: 2px solid #093D52;
    border-bottom: 2px solid #093D52;*/
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
}

.optcolhighlight label {
    background-color: #207AB6;
    margin-bottom: 0;
    width: 80%;
    cursor: pointer;
    padding: 6px;
    border-radius: 3px;
    color: #ffffff;
}

label.optLabel {
    position: relative;
}

label.resSelectOption {
    /*color: white;*/
    /*border: 2px solid #093D52;*/
    position: relative;
    background-color: #A66817;
    color: black;
}

.selectedOption {
    text-align: left;
    width: 100%;
    background: #ffffff;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 10px;
    border: 1px solid #e0e0e0;
}

    .selectedOption .votingOption {
        color: #1abc9c;
        padding: 6px;
        border-radius: 3px;
        width: 100%;
        font-weight: bold;
        font-size: 1.2rem;
    }

label.resConfirmOption {
    /*color: white;*/
    /*border: 2px solid #093D52;*/
    position: relative;
    background-color: #1abc9c;
    color: black;
}


.endButton {
    display: flex;
    align-items: stretch; /*vertical*/
    align-content: stretch; /*horizontal*/
}

    .endButton > div {
        padding: 5px;
    }

        .endButton > div:first-child {
            flex: 1;
            min-width: 100px;
        }

        .endButton > div:last-child {
            align-items: center;
            min-width: 60px;
        }

.btnExpand {
    background-color: var(--main-client-color);
    border-color: var(--main-client-color);
    color: var(--main-theme-color);
}

.confirmTick {
    display: inline;
    font-size: 10px;
}

@media screen and (max-width: 576px) {
    .confirmTick {
        padding-left: 10px;
    }
}

@media (min-width: 400px) {
    .confirmTick {
        line-height: 0.9;
        color: #1abc9c;
        font-size: 14px;
        display: block;
        position: absolute;
        /*        right: 10px;
        top: calc(50% - 10px);*/
        right: -5px;
        top: -5px;
        background-color: white;
        border-radius: 50%;
    }
}

/*---------- End Resolution Display ----------*/
/*-----Xvote Display------*/
.CandidateRow input[type=checkbox] {
    /*    width: 30px;
    height: 30px;
    margin-left: -7px;
    margin-right: -7px;
    -moz-transform: scale(2.5);*/
    /*position: fixed;*/
    position: absolute;
    z-index: -999;
}

.xboxparent {
    position: relative;
}

.xbox {
    width: 30px;
    height: 30px;
    border: 1px solid black;
    border-radius: 2px;
    font-size: 26px;
    padding: 5px;
    text-align: center;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background-color: #fff;
}


.CandidateRow {
    margin-bottom: 5px;
    background-color: #f9f9f9;
    padding: 5px;
    /*    margin-left: 5px;
    margin-right: 5px;*/
    border-radius: 3px;
    vertical-align: middle;
    border: 2px solid #efefef;
}

    .CandidateRow table {
        width: 100%;
    }

    .CandidateRow .row {
        margin-left: 0;
        margin-right: 0;
    }

    .CandidateRow .readMore {
        white-space: nowrap;
    }

    .CandidateRow label {
        margin-bottom: 0;
    }

    .CandidateRow > div > div {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
    }

.candNameAndStatement {
    width: calc(80% - 40px);
}

.xboxparent:focus-within .xbox {
    /*box-shadow: 0 0 2pt 1pt LightSkyBlue;*/
    box-shadow: 0 0 0 0.08rem rgba(0,0,0,0.8);
}

.candidatePhoto {
    max-width: 100%;
    height: auto;
    /*    margin-left: -10px;
    margin-right: -10px;*/
}

.CandidateRow col {
    display: block;
    min-width: 120px;
    border: 0px solid white;
    background-color: pink;
}

.CandidateStatement {
    margin: 5px;
}


/*-----End Xvote Display------*/

.candidateName {
    word-break: break-word;
}
/*---Preference Vote Display---- */
.candidatePreference {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 3px;
    font-weight: bold;
}

.candidateIndicator.failed {
    color: black;
    background-color: #FFA100;
}

.candidateIndicator.pending {
    color: black;
    border-color: #66D8C2;
}

.candidateIndicator.confirmed:not(button) {
    color: black;
    border-color: var(--main-client-color);
}

.preferenceVote .candidatePreference.btn:hover, .preferenceVote .candidateName.btn:hover {
    border: 1px solid #dee2e6;
}


.preferenceVote .btn-outline-dark.btn {
    border: none;
}
/*-----End preference vote display ----*/
/* general contest */
.continuousContest {
    margin-bottom: 0px;
}

    .continuousContest .card-body .cloud {
        border: 1px solid grey;
    }

    .continuousContest .card-header {
        background-color: #f9f9f9;
        border: 1px solid #efefef;
        border-radius: 1px;
        padding: 7px;
        opacity: 1;
        vertical-align: central;
        margin-bottom: 15px;
    }


        .continuousContest .card-header .endButton {
            border: 1px solid #eee;
            border-radius: 3px;
            padding: 5px;
            background-color: #fff;
        }

    .continuousContest .card-body {
        background-color: #fff;
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        border-top: 0 solid #eee;
    }

.cloudres .card-body {
    background-color: #fff;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-top: 0 solid #eee;
}

.card-body {
    padding: 8px;
}

.continuousContest h3 {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0px;
}

.contestExpand:hover {
    color: var(--main-theme-color);
}
/* end general contest */
/* ---------- Input Styling ---------------*/
input[type="text"], input[type="email"] {
    background-color: #ffffff;
    border: 1px solid #949494;
}

.form-control {
    border: 1px solid #949494;
}

.cloudres h3 {
    font-size: 1.2rem;
    font-weight: bold;
}
/* ---------- End Input Styling ---------------*/
/* ---------- Document Styling ---------------*/
.docBlock {
    background-color: rgba(0, 0, 0, 0.03);
    padding: 10px;
    margin-bottom: 10px;
}

    .docBlock div {
        display: inline-flex;
        align-items: center;
    }

.dIcon {
    width: 22px;
    color: #fc5c65;
}

.dName {
    flex: 1;
    min-width: 75px;
}

.DocViewer iframe {
    height: 75vh !important;
    width: 100%;
}

.docViewButton {
    width: 100%;
}

    .docViewButton button {
        margin-top: 0;
        margin-left: 0;
        margin-right: 10px;
        margin-bottom: 0;
        padding-top: 5px;
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 5px;
        background-color: var(--main-client-color);
        border-color: var(--main-client-color);
        color: var(--main-theme-color);
    }

.docBtn {
    background-color: var(--main-client-color);
    border-color: var(--main-client-color);
    color: var(--main-theme-color);
    margin-bottom: 5px;
    min-width: 100px;
    font-size: 1rem;
    margin-right: 5px;
}

    .docBtn:hover,
    .docBtn:focus {
        background-color: var(--main-theme-color);
        border-color: var(--main-client-color);
        color: var(--main-client-color);
    }

.documentFName {
    display: block;
    width: 100%;
    background: white;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
}
/* ---------- End Document Styling ---------------*/
/* ---------- VideoStream Styling ---------------*/
.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin-bottom: 0;
}

    .embed-container iframe, .embed-container object, .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

.videoStream {
    position: relative;
}

.vidContainer {
    display: flex;
    justify-content: center;
}

.videoSecondaryVid {
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    height: 20%;
    z-index: -5;
    border: 1px solid #093D52;
    outline: 1px solid white;
    padding-bottom: 3px;
    visibility: hidden;
}

.transparentClickOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 20%;
    height: 20%;
    z-index: 10;
    cursor: move;
    background-color: transparent;
}

.videoSlideController {
    width: 100%;
    background-color: #093D52;
    color: white;
    padding: 5px;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    display: none;
}



.videoSteamContainer {
    float: right;
    bottom: 0;
    width: 100%;
    height: auto;
    max-width: 65vw;
    box-shadow: 15px 14px 11px -10px rgba(0,0,0,0.36);
    -webkit-box-shadow: 15px 14px 11px -10px rgba(0,0,0,0.36);
    -moz-box-shadow: 15px 14px 11px -10px rgba(0,0,0,0.36);
    /*border: 1px solid red;*/
}

@media (max-width: 576px) {
    .videoSteamContainer {
        max-width: 100vw;
    }
}
/*IPAD*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .videoSteamContainer {
        max-width: 100vw;
        /*border: 1px solid red;*/
    }
}
/*IPAD Pro 10*/
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
    .videoSteamContainer {
        max-width: 80vw;
        /*border: 1px solid red;*/
    }

    .sidebar .bottom-row {
        bottom: 33px;
    }
}
/*IPAD Pro 12*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .videoSteamContainer {
        max-width: 100vw;
        /*border: 1px solid red;*/
    }
}
/*Laptop non retina*/
@media screen and (min-device-width: 1600px) and (max-device-width: 2500px) and (-webkit-min-device-pixel-ratio: 1) {
    .videoSteamContainer {
        max-width: 70vw;
    }
}
/*Laptop retina*/
@media screen and (min-device-width: 1600px) and (max-device-width: 2500px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    .videoSteamContainer {
        max-width: 70vw;
    }
}

.vidControls {
    text-align: right;
}

.switch {
    position: absolute;
    top: 0;
    z-index: 5;
    right: 0;
}

@media (min-width: 576px) {
    .videoSteamContainer {
        position: sticky;
        padding-top: 0px;
        top: 130px;
    }

    .videoSlideController {
        display: none;
    }

    .videoParent {
        position: relative;
    }
}

.voteMobile {
    display: none;
}

@media only screen and (max-width: 896px) {
    .voteMobile {
        display: inline-block;
        margin-top: 10px;
    }
}


.resultGrid .row {
    border-bottom: thin dotted 1px;
    border-color: #e0e0e0;
}

.resultGrid .col {
    border-bottom: thin dotted 1px;
    border-color: #e0e0e0;
}

.resultGraph {
    height: 170px;
}

.resultHeader {
    background-color: #e0e0e0;
}

.resultBarParent {
    height: 150px;
    text-align: center;
    border-left: 1px solid #f4f4f4;
    border-right: 1px solid #f4f4f4;
}

    .resultBarParent div {
        text-align: center;
    }

.resultBar {
    /* fallback for old browsers */
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
}

.resultGraph .resultBarParent:first-child .resultBar {
    background: #27ae60;
}

.resultGraph .resultBarParent:nth-child(2) .resultBar {
    background: #f1614e;
}

.resultGraph .resultBarParent:nth-child(3) .resultBar {
    background: #3498db;
}

.resolutionAccordion {
    width: 100%;
    background-color: #fff;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    padding: 5px;
}

.xVoteAccordion {
    width: 100%;
    background-color: #fff;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    padding: 10px;
}

.accordionFullWidth {
    width: 100%;
}

.xVoteAccordion .accordionFullWidth {
    width: 100%;
    background-color: #fff;
    border-left: 0px solid #eee;
    border-right: 0px solid #eee;
    border-bottom: 0px solid #eee;
    border-top: 0 solid #eee;
}

.xVoteAccordion .contestTitle {
    margin-bottom: 10px;
}

.card-body .accordionFullWidth {
    width: 100%;
    border-left: 0 solid #eee;
    border-right: 0 solid #eee;
    border-bottom: 0 solid #eee;
    border-top: 0 solid #eee;
    padding: 0;
}

.card-header {
    /*background-color: #EBF3F4;*/
    /*border-bottom:0;*/
    text-align: left;
    border: 0px solid #eaeaea;
    /*margin-bottom: 0;*/
    background: white;
}

.cloudres .card-header {
    border: 1px solid #eee;
}



.table .thead-light th {
    background-color: #f1f1f1;
    border-color: #dee2e6;
}
/* -------------- End Results ----------------*/
.expandMoreBtn {
    background-color: var(--main-client-color);
    color: var(--main-theme-color);
}
/* -------------- End Results ----------------*/
/* -------------- validations ----------------*/
.srv-validation-message {
    color: #e64040;
}


/* -------------- End validations ----------------*/



/* -------------- Loading ----------------*/
.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid var(--main-client-color); /* Blue */
    border-bottom: 4px solid var(--main-client-color); /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px 0 0 -10px;
    z-index: 1;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loaderHolder {
    position: relative;
}

.photoLoaderHolder {
    background-color: white;
    position: relative;
    width: 100%;
    min-height: 45px;
}

.chatButtonLoaderWrapper {
    display: inline-block;
    position: relative;
    min-width: 16px;
}

.loadingOverlay {
    width: 100%;
    height: 100%;
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.5);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }

    100% {
        background-position: 468px 0
    }
}



.chartLoader {
    position: relative;
    height: 8.6vw;
    width: 100%;
}

    .chartLoader .bar {
        display: inline-block;
        width: 20%;
        margin-left: 5%;
        animation-duration: 1.25s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #F6F6F6;
        background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
        background-size: 800px 104px;
        position: relative;
    }

        .chartLoader .bar.bar1 {
            height: 35%;
        }

        .chartLoader .bar.bar2 {
            height: 75%;
        }

        .chartLoader .bar.bar3 {
            height: 15%;
        }


/* -------------- End Loading ----------------*/
/*-------- Mobile menu --------*/
.navbar-dark .navbar-toggler {
    border-color: var(--main-client-color);
    background-color: var(--main-client-color);
    color: var(--main-theme-color);
    /*border-color: #e0e0e0;
    background-color: #7f8c8d;*/
}

.navbar-toggler-icon {
    color: var(--main-theme-color)
}

@media only screen and (max-width: 896px) {
    .nav {
        width: 100%;
    }

    .navbar {
        padding-bottom: 0;
    }
}


/* Overright thead React-Notifications CSS so dont get any opacity */

.notification {
    box-sizing: border-box;
    padding: 15px 15px 15px 58px;
    border-radius: 2px;
    color: #fff;
    box-shadow: 0 0 12px #999;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.2em;
    position: relative;
    opacity: 1;
    margin-top: 15px;
}

    .notification .title {
        color: #fff !important;
    }

/*----Proxy Poll Card---*/
.proxyResSelectOption {
    /*    outline: red solid 3px;*/
}

.makebtnRadio {
    display: flex;
    flex-wrap: nowrap;
}

.btnRadio {
    display: inline-block;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0 3px grey;
    border: 1px solid white;
    height: 13px;
    width: 13px;
    margin-right: 5px;
    padding: 1px;
}

.proxyResSelectOption .btnRadio {
    background-color: blue;
}

.btnRadioText {
    display: inline-block;
    padding: 1px;
}

.datatableParent {
    position: relative;
    background-color: white;
    border-radius: 5px;
    padding: 15px;
}

.rejectOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(201, 201, 201, 0.8);
    width: 100%;
    height: 100%;
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    font-weight: bold;
    font-size: 1.5em;
}

.proxypollcarddata {
    width: 100%;
    margin: 15px;
    border-radius: 10px;
    color: #555555;
}

    .proxypollcarddata td {
        text-align: center;
        padding: 3px;
    }

@media (min-width: 576px) {
    .proxypollcarddata td:not(:first-child) {
        width: 125px;
    }
}

.proxypollcarddata td.proxyResTitle {
    text-align: left;
}

.proxypollcarddata tr {
    width: 100%;
}

/*    .proxypollcarddata tr:nth-child(even) td {
        padding-bottom: 15px;
    }*/

.proxypollcarddata td:nth-child(1) {
    font-weight: bold;
}

.proxypollcarddata th {
    text-align: center;
    background-color: #F9F9F9;
    padding: 5px;
}

@media (max-width: 575px) {
    .proxypollcarddata td {
        text-align: left;
        padding-left: 25px;
    }

    td.proxyResTitle {
        display: block;
        width: 100%;
    }
}

.acceptReject input[type=checkbox] {
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    margin-left: 15px;
    margin-right: 15px;
}

.errorbox {
    border: 1px solid red;
    color: red;
    border-radius: 8px;
    padding: 20px;
}
/*---- End Proxy Poll Card---*/
/*--Help and policy--*/


.helppolicy li p:first-child {
    font-weight: bold;
}

.logoimg {
    max-width: 200px;
    height: auto;
    margin-left: 25px;
    margin-bottom: 25px;
}
/*--End Help and policy--*/



@media only screen and (max-width: 500px) {
    .askaquestionbox .askaquestion {
        right: 0;
        bottom: 0;
        z-index: 1;
        margin-right: 10px;
        margin-bottom: 5px;
    }
}

.HiddenLabelForm .form-group label {
    position: absolute;
    left: -999999px;
}

.menuVoteAlert {
    display: inline-block;
    color: #ffffff;
    background-color: #CE2C2C;
    border-radius: 3px;
    padding-left: 4px;
    padding-right: 4px;
}

.voteAlert {
    color: #ffffff;
    background-color: #CE2C2C;
    border-radius: 3px;
    padding: 4px;
    font-size: 1rem;
}

.resultAlert {
    color: #ffffff;
    background-color: #CE2C2C;
    border-radius: 3px;
    padding: 4px;
}

.voteComplete {
    color: #1abc9c;
    padding: 2px;
    font-size: 1.3rem;
}


.textLength {
    display: block;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    padding-left: 10px;
    color: grey;
    background-color: white;
    width: 100%;
    font-size: .8rem;
}


/* Don't mess with the ratio's as it will break - reactplayer */
.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    margin-bottom: 40px; /* show volume on mobile*/
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.videoControls {
    position: absolute;
    bottom: -40px;
}

    .videoControls label {
        padding-right: 10px;
        padding-bottom: 0px;
        vertical-align: middle;
    }

    .videoControls.volume {
        right: 0px;
    }

.form-control::placeholder {
    opacity: 1;
    color: #6D767E;
}

/*---Question colours--*/

.genChat {
    color: #3188ed;
    display: inline-block;
}

.speakLive {
    color: #e65d5d;
    display: inline-block;
}

.pointOrder {
    color: #48ca7f;
    display: inline-block;
}

.techSupport {
    color: #9012a1;
    display: inline-block;
}




.scrollBtn:before {
    /*content: "\f078"*/
}

.scrollBtn:focus {
    /*border: solid 1px #80bdff !important;*/
    border-radius: 10px !important;
    box-shadow: 0 0 0 0.1rem rgba(0,0,0,0.8);
}

.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(0,0,0,0.8);
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #fff;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(0,0,0,0.8);
}



.input-group-append .btn {
    border-radius: 0;
}

a.skiptocontent {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

    a.skiptocontent:focus {
        position: static;
        width: auto;
        height: auto;
    }

.emojiPicker {
    background-color: #f1f1f1;
}

.emojiButton {
    flex-basis: 0px;
    flex-shrink: 1;
    min-width: 2.2rem;
    text-align: center;
    transition: all .2s ease-in-out;
    background: rgba(0,0,0,0);
}

    .emojiButton:hover {
        transform: scale(1.5);
    }

.emoji-icon {
    font-size: 1.4rem;
    cursor: pointer
}
/*--------Tabs-------*/
.getInvolvedSection {
    border: 0.5px solid #efefef;
    background-color: #fff;
}

.react-tabs__tab {
    color: black;
    background-color: rgb(246 243 243 / 0.51);
    border-radius: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: 0.5px solid #575757;
    border-bottom: 1px solid #575757;
}

.react-tabs > ul > li {
    border: 0.5px solid #e0e0e0;
    border-right: none;
    color: #575757;
}

.getInvolved .react-tabs > ul > li:last-child {
    border-right: 0.5px solid #e0e0e0;
}

.getInvolved .react-tabs__tab--selected {
    color: var(--main-theme-color) !important;
    background-color: var(--main-client-color) !important;
    border-radius: unset !important;
}

.getInvolved .react-tabs__tab-list {
    border: none;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0 !important;
    margin-top: 0px;
}

.getInvolved .react-tabs__tab--disabled {
    display: none;
}
/*--------End Tabs-------*/

/*-------Chat Window NEW QA----------*/
.customMessage {
    font-size: 0.85rem;
    padding: 5px;
}

.title {
    font-size: 1.1rem;
    font-weight: 400;
}

.messageInput {
    font-size: 0.85rem
}

.inboxMessges {
    height: 300px;
    overflow-y: scroll
}
/*-------End Chat Window-------------*/

/*--------Survey--------*/

.form-check-input:checked {
    background-color: var(--main-client-color);
    border-color: var(--main-client-color);
}

.slidecontainer {
    width: 100%; /* Width of the outside container */
}

.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

    .slider:hover {
        opacity: 1;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: var(--main-client-color);
        cursor: pointer;
    }

    .slider::-moz-progress-bar {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: var(--main-client-color);
        cursor: pointer;
    }

.form-div {
    margin-top: 2rem;
}

.form-text {
    margin-top: 0.5rem;
}

.card-div {
    margin-top: 1rem;
}

.button-margin {
    margin-top: 1rem;
}

.button-margin-2 {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.alert-margin {
    margin-top: 2rem;
}

.header-margin {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.dnd {
    cursor: move;
    transition: all 0.2s;
}

    .dnd:hover {
        background-color: #e2e3e5 !important;
    }

/*--------Survey End--------*/


/*------------Zoom-------------*/

#meetingSDKElement {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    overflow: hidden;
}

#zmmtg-root {
    /*display: none;*/
}

.meeting-info-icon__button-wrap, .meeting-info-icon__icon-wrap.ax-outline-blue {
    display: none;
}

.zoom-workplace-logo {
    display: none;
}
/*------------Zoom End-----------*/
